import { GetServerSideProps } from "next";
import { Meta } from "../types/Meta";
import { Block } from "../types/Block";
import SEO from "../src/components/seo";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { MenuRoute } from "../types/MenuRoute";
import { City } from "../types/City";
import React, { useEffect } from "react";
import Layout from "../src/components/layout";
import { getBaseDataForPage } from "../utils/getBaseDataForPage";
import { TopBar } from "../types/TopBar";
import HttpClient from "../utils/HttpClient";
import { Slug } from "../types/Slug";
import DynamicPageBuilder from "../src/components/DynamicPageBuilder";
import { useCitiesState } from "../utils/CitiesContext";
import { optimizeActivateEvent } from "../utils/gtmEvents";
import { useRouter } from "next/router";
import { CompanyData } from "../types/CompanyData";

type HomeProps = {
  blocks: Block[];
  meta: Meta;
  routes: MenuRoute[];
  cities: City[];
  topBars: TopBar[];
  slugs: Slug[];
  companyData: CompanyData;
  hostUrl: string;
};

const Home = (data: HomeProps): React.ReactElement => {
  const { isReady, asPath } = useRouter();
  const { meta, blocks, cities } = data;
  const { update } = useCitiesState();
  useEffect(() => {
    update(cities);
  }, [cities]);

  useEffect(() => {
    if (isReady) {
      optimizeActivateEvent();
    }
  }, [asPath]);

  return (
    <>
      <SEO
        description={meta.description}
        title={meta.title}
        picture={meta.picture}
      />
      <DynamicPageBuilder blocks={blocks} />
    </>
  );
};

Home.getLayout = function getLayout(
  data: HomeProps,
  component: React.ReactElement
) {
  const { routes, topBars, slugs, companyData, hostUrl } = data;

  return (
    <Layout
      topBars={topBars}
      routes={routes}
      slugs={slugs}
      companyData={companyData}
      hostUrl={hostUrl}
    >
      {component}
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  res,
  req,
}) => {
  let props = {};

  if (process.env.NEXT_PUBLIC_CACHE_CONFIG) {
    res.setHeader("Cache-Control", process.env.NEXT_PUBLIC_CACHE_CONFIG);
  }

  if (locale) {
    const { host: hostUrl } = req.headers;
    const httpClient = new HttpClient(locale);
    const data = await getBaseDataForPage(httpClient);

    props = {
      hostUrl,
      ...data,
      ...props,
      ...(await serverSideTranslations(locale, ["common"])),
    };
  }

  return { props };
};

export default Home;
